import React, { ComponentProps } from 'react';
import clx from 'classnames';

import Button, { Variants, Sizes } from '../Button/Button';
import Link from '../../../../NextJs/Link/Link';

type PickedButtonProps = Pick<ComponentProps<typeof Button>, 'variant' | 'size' | 'disabled'>;

type LinkProps = React.ComponentProps<typeof Link>;

interface Props extends PickedButtonProps, LinkProps {
    children: React.ReactNode;
}

/**
 * Wrapper for using our custom NextJS link component and our button styles
 */
const ButtonLink = ({ children, href, title, size = 'default', variant = 'primary', className, disabled, ...props }: Props) => {
    const classNames = clx(
        'button inline-block',
        Sizes[size],
        Variants[variant],
        disabled ? 'button-disabled' : '',
        className
    );

    const onClick = disabled ? (e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault() : undefined;

    return (
        <Link href={href} title={title} className={classNames} onClick={onClick} {...props}>
            {children}
        </Link>
    );
};

export default ButtonLink;
