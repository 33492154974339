export const API_ERROR_MESSAGE =
    'Oops, something went wrong. Please refresh the page or log out and back into your account to try again. If this continues to happen, please contact support.';

// Format example: 3 Dec 2021.
export const DATE_FORMAT_SHORT = 'D MMM YYYY';

// Format example: 3 December 2021.
export const DATE_FORMAT_FULL = 'D MMMM YYYY';

export const ORDER_TYPES = {
    odonly: 'ODONLY',
    pmed: 'PMED',
    prescription: 'PRESCRIPTION',
    product: 'PRODUCT',
};

export const QUANTITY_TYPES = {
    regular: 'REGULAR',
    timed: 'TIMED',
};
