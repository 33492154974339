'use client';

import React, { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

import { API_ERROR_MESSAGE } from '../../constants';

import Typography from '../../components/_ui/_blocks/Typography/Typography';
import ButtonLink from '../../components/_ui/_blocks/Buttons/ButtonLink/ButtonLink';

interface Props {
    error: unknown;
}

const Error = ({ error }: Props) => {
    useEffect(() => {
        Sentry.captureException(error);
    }, []);

    return (
        <section className="py-600 w-[343px] mx-auto sm:w-[432px] md:py-800 lg:w-[448px]">
            <div className="flex flex-col items-center px-[theme('spacing.100')] md:px-[theme('spacing.200')] lg:mx-[theme('spacing.100')]">
                <Typography typeset="title" size="600" className="text-center" color="accent">
                    Internal server error
                </Typography>
                <Typography typeset="paragraph" size="100" color="foundation" className="my-[theme('spacing.100')]">
                    {API_ERROR_MESSAGE}
                </Typography>
                <ButtonLink title="Contact us" className="max-w-[200px] w-full text-center" href="/contact-us">
                    Contact us
                </ButtonLink>
            </div>
        </section>
    );
};

export default Error;
